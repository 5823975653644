import React from 'react';

import { Img } from 'utilities/components/img/component';

import fireExtinguisher from 'assets/images/investments/fire-extinguisher.svg';
import antiBurglary from 'assets/images/investments/anti-burglary.svg';
import acoustic from 'assets/images/investments/acoustic.svg';
import flame from 'assets/images/investments/flame.svg';
import shield from 'assets/images/investments/shield.svg';
import music from 'assets/images/investments/music.svg';

import './view.scss';

export const DoorTypesView = () => (
    <section className="door-types">
        <div className="door-types__item">
            <div className="door-types__icon">
                <Img src={ fireExtinguisher } alt="gaśnica" />
            </div>
            <div className="door-types__container">
                <h4 className="door-types__headline">Drzwi przeciwpożarowe</h4>
                <p className="door-types__description">Bezpieczeństwo przeciwpożarowe to jeden z podstawowych aspektów projektowania biurowców czy budynków użyteczności publicznej. Istnieje możliwość zamówienia wybranych modeli drzwi Barański w wersjach przeciwpożarowych - posiadamy certyfikaty potwierdzające dwie klasy odporności ogniowej: EI 30 oraz EI 60.</p>
                <div className="door-types__info">
                    <Img src={ flame } alt="płomień" />
                    <span>EI 30</span>
                    <Img src={ flame } alt="płomień" />
                    <span>EI 60</span>
                </div>
            </div>
        </div>
        <div className="door-types__item">
            <div className="door-types__icon">
                <Img className="door-types__icon" src={ antiBurglary } alt="włamywacz" />
            </div>
            <div className="door-types__container">
                <h4 className="door-types__headline">Drzwi antywłamaniowe</h4>
                <p className="door-types__description">Zabezpieczenie mieszkania czy domu przed nieuprawnionym dostępem osób trzecich to podstawowa funkcja drzwi. Dlatego w naszej ofercie posiadamy modele o specjalnych parametrach antywłamaniowych. Dysponujemy certyfikatami RC2 i RC3, które potwierdzają jakość naszych drzwi i ich odporność na próby włamania.</p>
                <div className="door-types__info">
                    <Img src={ shield } alt="tarcza" />
                    <span>RC2</span>
                    <Img src={ shield } alt="tarcza" />
                    <span>RC3</span>
                </div>
            </div>
        </div>
        <div className="door-types__item">
            <div className="door-types__icon">
                <Img className="door-types__icon" src={ acoustic } alt="drzwi" />
            </div>
            <div className="door-types__container">
                <h4 className="door-types__headline">Drzwi akustyczne</h4>
                <p className="door-types__description">
                    Komfort pomieszczenia zależy od ciszy i spokoju, jaki zapewnia ono jego użytkownikom. W lokalach położonych w pobliżu ruchliwych miejsc lub w hałaśliwym sąsiedztwie
                    kluczową rolę odgrywa odpowiednia izolacja akustyczna. W naszej ofercie posiadamy modele drzwi o trzech poziomach dźwiękoszczelności: 34 dB, 39 dB i - dla najbardziej wymagających - 44 dB.
                </p>
                <div className="door-types__info">
                    <Img src={ music } alt="nuta" />
                    <span>34dB, 39dB oraz 44dB</span>
                </div>
            </div>
        </div>
    </section>
);