import React from 'react';
import PropTypes from 'prop-types';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { Img } from 'utilities/components/img/component';

import contact from 'assets/images/investments/contact.svg';

import './view.scss';

export const ContactView = ({ data }) => (
    <div className="contact">
        <HeadlineView text="Skontaktuj się z naszym specjalistą" />
        <p className="contact__description">
            Zachęcamy do składamy zapytań inwestycyjnych - z przyjemnością doradzimy, potwierdzimy możliwości techniczne i wycenimy projekt oraz z naszymi partnerami zrealizujemy inwestycję.
        </p>
        <Img className="contact__icon" src={ contact } alt="kierownik" />
        <div className="contact__name">{ data?.investment_name_surname }</div>
        <div className="contact__position">{ data?.investment_position }</div>
        <div className="contact__phone">
            <span className="contact__phone-label">tel. </span>
            <span className="contact__phone-value">{ data?.investment_phone }</span>
        </div>
        <div className="contact__mail">
            <span className="contact__mail-label">e-mail: </span>
            <a className="contact__mail-value" href={ `mailto:${data?.investment_email}` }>{ data?.investment_email }</a>
        </div>
    </div>
);

ContactView.propTypes = {
    data: PropTypes.instanceOf(Object).isRequired
};