import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { ContactView } from './view';

export const Contact = () => {
    const data = useStaticQuery(graphql`
        query {
            allContacts(filter: {
                is_active: {
                    eq: 1
                }
            }) {
                nodes {
                    investment_name_surname
                    investment_position
                    investment_phone
                    investment_email
                }
            }
        }
    `);

    return (<ContactView data={ data?.allContacts?.nodes[0] } />);
};