import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

import { ButtonsView } from 'utilities/components/buttons/component/view';
import { HeadlineView } from 'utilities/components/headline/component/view';
import { Img } from 'utilities/components/img/component';

import quotationMark from 'assets/images/quality/quotation-mark.svg';

import './view.scss';

SwiperCore.use([Navigation]);

export const ReferencesView = ({ references }) => {
    const sliderButtonsName = 'references';

    return (
        <section className="references">
            <div className="references__container">
                <HeadlineView text="Referencje" />
                <Swiper
                    className="references__carousel"
                    navigation={{
                        nextEl: `.${sliderButtonsName}--next`,
                        prevEl: `.${sliderButtonsName}--previous`
                    }}
                    loop
                >
                    {
                        references.map((item, index) => (
                            <SwiperSlide className="carousel__item" key={ index }>
                                <h4 className="item__title">{ item?.name }</h4>
                                <p className="item__subtitle">
                                    { item?.order_short_desc }
                                </p>
                                <div className="item__text">
                                    <span>
                                        { item?.content }
                                    </span>
                                    <Img className="references__icon" src={ quotationMark } alt="cudzysłów" />
                                    <Img className="references__icon" src={ quotationMark } alt="cudzysłów" />
                                </div>
                                <div className="item__author">
                                    <p className="item__author-name">{ item?.author }</p>
                                    <p className="item__author-info-1">
                                        { item?.author_title_line1 }
                                    </p>
                                    <p className="item__author-info-2">
                                        { item?.author_title_line2 }
                                    </p>
                                    <p className="item__author-info-3">
                                        { item?.author_title_line3 }
                                    </p>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            <ButtonsView name={ sliderButtonsName } />
        </section>
    );
};

ReferencesView.propTypes = {
    references: PropTypes.instanceOf(Array).isRequired
};