import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { ReferencesView } from './view';

export const References = () => {
    const data = useStaticQuery(graphql`
        query GetAllCustomerreference {
            allCustomerreference(filter: {in_investment: {eq: 1}, is_active: {eq: 1}}) {
                nodes {
                  author
                  alternative_id
                  author_title_line1
                  author_title_line2
                  author_title_line3
                  content
                  id
                  in_investment
                  in_quality
                  is_active
                  name
                  order_short_desc
                }
            }
        }
    `);

    return (<ReferencesView references={ data?.allCustomerreference?.nodes } />);
};