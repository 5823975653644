import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { URL } from 'utilities/consts';

import { GalleryModal } from 'features/realizations/gallery-modal/component';
import { Img } from 'utilities/components/img/component';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { ButtonsView } from 'utilities/components/buttons/component/view';

import './view.scss';

SwiperCore.use([Navigation]);

export const OurWorkView = ({
    realization,
    realizations,
    realizationId,
    setRealizationId
}) => {
    const sliderButtonsName = 'our-work';
    return (
        <section className="our-work">
            <HeadlineView text="Nasze inwestycje" />
            <Swiper
                className="our-work__carousel"
                navigation={{
                    nextEl: `.${sliderButtonsName}--next`,
                    prevEl: `.${sliderButtonsName}--previous`
                }}
                loop
                slidesPerView="auto"
                breakpoints={{
                    600: {
                        slidesPerView: 'auto'
                    }
                }}
                centerInsufficientSlides
            >
                {
                    realizations?.map((item, index) => (
                        <SwiperSlide className="our-work__item" key={ index } style={{ width: 'auto' }}>
                            <button
                                type="button"
                                onClick={ () => {
                                    setRealizationId(item?.alternative_id);
                                } }
                            >
                                <Img src={ `${URL}/${item?.main_image}` } alt={ item?.name } />
                                <div className="item__info">
                                    <p>{ item?.name }</p>
                                    <p>{ item?.place }</p>
                                </div>
                            </button>
                        </SwiperSlide>
                    ))
                }
                <ButtonsView name={ sliderButtonsName } />
            </Swiper>
            {
                realizationId
                && <GalleryModal setRealizationId={ (id) => setRealizationId(id) } onClose={ () => setRealizationId(undefined) } realization={ realization } />
            }
        </section>
    );
};

OurWorkView.propTypes = {
    realizations: PropTypes.instanceOf(Array),
    realization: PropTypes.oneOfType([PropTypes.object]),
    realizationId: PropTypes.string,
    setRealizationId: PropTypes.func.isRequired
};

OurWorkView.defaultProps = {
    realizations: undefined,
    realization: undefined,
    realizationId: undefined
};