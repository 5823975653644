import React, { useEffect, useState } from 'react';

import { useQueryParam, NumberParam } from 'use-query-params';

import { OurWorkView } from './view';
import { graphql, useStaticQuery } from 'gatsby';

export const OurWork = () => {
    const [realizationId, setRealizationId] = useQueryParam('id', NumberParam);
    // TODO - dodać alt_main_image kiedy zmienimy w CMS
    const data = useStaticQuery(graphql`
        query GetInvestmentsSlider {
            allInvestmentsSlider(filter: {id: {ne: "dummy"}}) {
                nodes {
                  name
                  alternative_id
                  city
                  date
                  images
                  main_image
                  country
                  main_image_alt
                }
              }
        }
    `);

    const [realization, setRealization] = useState(null);

    const [realizations, setRealizations] = useState(null);

    useEffect(() => {
        setRealizations(() => data?.allInvestmentsSlider?.nodes ?? []);
    }, []);

    useEffect(() => setRealization(() => realizations?.find((item) => item?.alternative_id === realizationId)), [realizationId, realizations]);

    return (<OurWorkView realizations={ data?.allInvestmentsSlider?.nodes ?? [] } realizationId={ realizationId } realization={ realization } setRealizationId={ setRealizationId } />);
};