import React, { useEffect, useState } from 'react';
import { GalleryModalView } from './view';
import PropTypes from 'prop-types';
import queryString from 'query-string';

export const GalleryModal = ({
    onClose,
    realization,
    setRealizationId
}) => {
    const [activeImage, setActiveImage] = useState(0);

    useEffect(() => setRealizationId && setRealizationId(() => {
        const parsed = queryString.parse(window.location.search);
        return parsed.id;
    }), []);

    return (<GalleryModalView onClose={ onClose } realization={ realization } activeImage={ activeImage } setActiveImage={ (number) => setActiveImage(number) } />);
};

GalleryModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    realization: PropTypes.oneOfType([PropTypes.object]),
    setRealizationId: PropTypes.func.isRequired
};

GalleryModal.defaultProps = {
    realization: undefined
};