import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import { routes } from 'utilities/routes';

import { Navigation } from '../utilities/components/navigation/component';
import { DoorTypesView } from '../features/investments/door-types/component/view';
import { BannerView } from '../utilities/components/banner/component/view';
import { References } from '../features/investments/references/component/index';
import { Contact } from '../features/investments/contact/component';
import { OurWork } from '../features/investments/our-work/component/index';
import { Footer } from '../utilities/components/footer/component';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import LogRocket from 'logrocket';
import SEO from 'utilities/components/seo';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

LogRocket.init('qijaj0/baranski');

const Index = () => {
    const breadcrumbs = [routes.home, routes.inwestycje];
    const data = useStaticQuery(graphql`
        query GetBannerInwestycje {
            banners(site_type: { eq: "Inwestycje" }) {
                image
                alt_image
                background_position_x
            }
        }
    `);

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };
    const { metaTitle, metaDescription, description } = useSeoTexts('Inwestycje');

    return (
        <main className="investments">
            <SEO
                title={ metaTitle || 'Barański Drzwi - Zamówienia dla inwestycji budowlanych' }
                description={
                    metaDescription
                    || 'Prowadzisz dużą inwestycję albo kapitalny remont budynku? Skontaktuj się z nami i dobierz drzwi do swojego projektu.'
                }
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                alt={ data?.banners?.alt_image }
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <BannerView
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                headline="Inwestycje"
                x={ data?.banners?.background_position_x }
            />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <DoorTypesView />
            <References />
            <OurWork />
            <Contact />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Index;
