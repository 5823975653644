/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { URL } from 'utilities/consts';
import { paths } from 'utilities/routes';

import { ButtonsView } from 'utilities/components/buttons/component/view';
import { ButtonView } from 'utilities/components/button/component/view';
import { Modal } from 'utilities/components/modal';
import { Img } from 'utilities/components/img/component';

import baranskiPremium from 'assets/images/baranski-premium.svg';
import baranskiOptimo from 'assets/images/baranski-optimo.svg';
import baranskiSmart from 'assets/images/baranski-smart.svg';
import shareIcon from 'assets/images/share.svg';
import brushIcon from 'assets/images/brush.svg';
import glassIcon from 'assets/images/glass.svg';
import frameIcon from 'assets/images/frame.svg';
import handleIcon from 'assets/images/handle.svg';
import CloseIcon from 'assets/images/close.svg';

import './view.scss';

SwiperCore.use([Navigation]);

export const GalleryModalView = ({
    realization,
    activeImage,
    setActiveImage,
    onClose
}) => {
    const [mySlider, setMySlider] = useState(null);
    const [isPopup, setIsPopup] = useState(false);

    const sliderButtonsName = 'gallery-modal';

    return (
        <div className="gallery-modal">
            <div className="gallery-modal__backdrop" onClick={ onClose } />
            <div className="gallery-modal__card">
                <button
                    className="gallery-modal__close"
                    type="button"
                    onClick={ onClose }
                >
                    <Img src={ CloseIcon } alt="zamknij" />
                </button>
                <div className="gallery-modal__slider">
                    <Swiper
                        className="gallery-modal__carousel"
                        navigation={{
                            nextEl: `.${sliderButtonsName}--next`,
                            prevEl: `.${sliderButtonsName}--previous`
                        }}
                        slidesPerView="1"
                        centerInsufficientSlides
                        onSlideChange={ (e) => setActiveImage(e.activeIndex) }
                        onSwiper={ (swiper) => setMySlider(swiper) }
                    >
                        {
                            realization && [realization?.main_image, ...realization?.images]?.map((item, index) => (
                                <SwiperSlide className="gallery-modal__item" key={ index }>
                                    <Img src={ `${URL}/${item}` } alt="" className="gallery-modal__image" />
                                </SwiperSlide>
                            ))
                        }
                        <ButtonsView name={ sliderButtonsName } />
                    </Swiper>
                </div>
                <div className="gallery-modal__info">
                    <div className="gallery-modal__info-top">
                        {
                            (realization?.lines?.brands)
                                && (
                                    <Img
                                        className="gallery-modal__brand"
                                        src={
                                            (realization?.lines?.brands?.name === 'Barański Premium')
                                                ? (baranskiPremium)
                                                : (realization?.lines?.brands?.name === 'Barański Optimo')
                                                    ? (baranskiOptimo)
                                                    : (realization?.lines?.brands?.name === 'Barański Smart')
                                                        && (baranskiSmart)
                                        }
                                        alt=""
                                    />
                                )
                        }
                        <button className="gallery-modal__share gallery-modal__share--right" type="button" onClick={ () => setIsPopup(true) }>
                            <Img src={ shareIcon } alt="" />
                            <span>Udostępnij</span>
                        </button>
                    </div>
                    <p className="gallery-modal__model">
                        { realization?.name }
                    </p>
                    {
                        (realization?.country || realization?.city)
                        && (
                            <p className="gallery-modal__localization">
                                {
                                    realization?.country
                                    && <span>{realization?.country}</span>
                                }
                                {
                                    (realization?.country && realization?.city)
                                    && <span>, </span>
                                }
                                {
                                    realization?.city
                                    && <span>{realization?.city}</span>
                                }
                            </p>
                        )
                    }
                    <p className="gallery-modal__line">
                        { realization?.lines?.name }
                    </p>
                    <p className="gallery-modal__type">
                        { realization?.lines?.brands?.type }
                    </p>
                    <p className="gallery-modal__date">
                        { realization?.date }
                    </p>
                    <div className="gallery-modal__photos">
                        <p className="gallery-modal__photos-title">
                            Galeria
                        </p>
                        <div className="gallery-modal__photos-items">
                            {
                                realization && [realization?.main_image, ...realization?.images]?.map((item, index) => (
                                    <button
                                        key={ index }
                                        className={ `gallery-modal__photos-button ${index === activeImage ? 'gallery-modal__photos-button--active' : ''}` }
                                        type="button"
                                        onClick={ () => {
                                            setActiveImage(index);
                                            mySlider.slideTo(index);
                                        } }
                                    >
                                        <Img src={ `${URL}/${item}` } alt="" className="gallery-modal__photos-image" />
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                    {/* TODO - navigate to product */}
                    {
                        realization?.link_product
                        && <ButtonView text="Przejdź do produktu" link={ `${paths.produkt}/${realization?.link_product}` } />
                    }
                    <div className="gallery-modal__others">
                        {
                            realization?.trims
                            && (
                                <div className="gallery-modal__color">
                                    <Img src={ `${URL}/${realization?.trims?.image}` } alt="kolor" />
                                    <p>{ realization?.trims?.name }</p>
                                </div>
                            )
                        }
                        {
                            realization?.color
                            && (
                                <div className="gallery-modal__color">
                                    <Img src={ brushIcon } alt="kolor" />
                                    <p>{ realization?.color }</p>
                                </div>
                            )
                        }
                        {
                            realization?.glass
                            && (
                                <div className="gallery-modal__glass">
                                    {/* TODO - is alt correct? */}
                                    <Img src={ glassIcon } alt="szkło" />
                                    <p>{ realization?.glass }</p>
                                </div>
                            )
                        }
                        {
                            realization?.frame
                            && (
                                <div className="gallery-modal__frame">
                                    <Img src={ frameIcon } alt="ościeżnica" />
                                    <p>{ realization?.frame }</p>
                                </div>
                            )
                        }
                        {
                            realization?.accessory
                            && (
                                <div className="gallery-modal__handle">
                                    <Img src={ handleIcon } alt="akcesoria" />
                                    <p>{ realization?.accessory }</p>
                                </div>
                            )
                        }
                        {
                            realization?.addon
                            && (
                                <div className="gallery-modal__accessories">
                                    <span>Dodatki: </span>
                                    { realization?.addon }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            {
                isPopup && <Modal imageURI={ realization.image } onClose={ () => setIsPopup(false) } />
            }
        </div>
    );
};

GalleryModalView.propTypes = {
    realization: PropTypes.oneOfType([PropTypes.object]).isRequired,
    activeImage: PropTypes.number.isRequired,
    setActiveImage: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};